<template>
  <section id="get-started" ref="getStarted" class="main-body-wrapper">
    <div class="d-flex flex-column align-items-center">
      <div class="page-title pb-1">
        <div>
          <span style="font-size: xx-large; font-weight: normal">
            Welcome to {{ facilitator.name }}
          </span>
        </div>
        <h2 class="sub-title">Let's start by verifying your contact information.</h2>
      </div>
      <div class="form-wrapper mb-0">
        <div class="container-fluid p-0">
          <form @submit.prevent="submitForm">
            <BaseCustomInput
              ref="input-name"
              name="name"
              label="Full name"
              required
              focus
              :error="getError('name')"
              v-model="name"
              v-validate="modelValidations.name"
              @keydown.enter.prevent="focusNext('input-name', 'input-email')"
            />
            <BaseCustomInput
              ref="input-email"
              name="email"
              label="Email address"
              required
              :error="getError('email')"
              v-model="email"
              v-validate="modelValidations.email"
              @keydown.enter.prevent="focusNext('input-email', 'input-password')"
            />
            <SimplePasswordInput
              ref="input-password"
              label="Create Password"
              name="password"
              required
              :error="getError('password')"
              v-model="password"
              v-validate="modelValidations.password"
              @enter-pressed="focusNext('input-password', 'terms')"
            />
            <div class="company-tnc-box">
              <div class="company-tnc-checkbox">
                <input
                  ref="terms"
                  type="checkbox"
                  name="terms"
                  class="onboarding-input"
                  style="margin-top: 4.4px; margin-right: 5px; width: 30px; height: 30px"
                  v-model="terms"
                  v-validate="modelValidations.terms"
                  @keydown.enter.prevent="toggleCheckboxAndFocusNext('submit-button')"
                />
              </div>
              <div class="company-tnc-links">
                <a tabindex="-1">
                  <p>
                    <span style="font-weight: 200; font-size: 14px" @click="accept"
                      >I agree to {{ facilitator.name }}'s</span
                    >&nbsp;
                    <a
                      href
                      data-toggle="modal"
                      data-target="#terms-of-service"
                      tabindex="-1"
                      >General Terms of Service</a
                    >,
                    <a
                      href
                      data-toggle="modal"
                      data-target="#privacy-policy"
                      tabindex="-1"
                      >Privacy Policy</a
                    >,
                    <a href data-toggle="modal" data-target="#e-sing" tabindex="-1"
                      >E-sign Consent</a
                    >
                    <span style="font-weight: 200; font-size: 14px" @click="accept"
                      >, and our</span
                    >&nbsp;
                    <a
                      href="https://help.jupico.com/references-and-resources/our-commitment-to-security"
                      tabindex="-1"
                      >Commitment to Security</a
                    >.
                  </p>
                </a>
                <i class="error-msg" v-if="getError('terms')">{{ getError('terms') }}</i>
              </div>
            </div>
            <div id="captcha-wrapper">
              <vue-recaptcha
                @verify="onVerify"
                sitekey="6LdsKo8UAAAAAF_aV2C93G2C0ly8Ii-xNS99zYDW"
              ></vue-recaptcha>
              <i class="error-msg" v-if="captchaError"
                >Please verify that you are not a robot.</i
              >
            </div>
            <div class="alert alert-danger alert-msg mb-md-3" v-if="error">
              <i aria-hidden="true" class="fa fa-exclamation-triangle mr-2"></i>
              The application has been submitted already, please log in to the backoffice
              at
              <a :href="backofficeUrl" target="_blank">{{ backofficeUrl }}</a>
              or check with your representative.
            </div>
            <div class="form-actions d-flex flex-column align-items-center">
              <button
                ref="submit-button"
                type="submit"
                class="btn btn-primary continue-button"
                :disabled="submitted || isSubmitting"
              >
                {{ isSubmitting ? 'Processing...' : 'Start Application' }}
              </button>
              <button
                type="button"
                class="btn btn-secondary prev-app-button ml-2"
                @click="continueApplication"
                :disabled="submitted || isSubmitting"
              >
                Continue previous application
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <span v-if="APP_VERSION" class="app-version"
      >&copy; {{ year }}, {{ facilitator.name }} v{{ APP_VERSION }}</span
    >
  </section>
</template>

<script>
import swal from 'sweetalert2';
import VueRecaptcha from 'vue-recaptcha';
import { mapGetters } from 'vuex';

import { validPhoneFormat } from '@/helpers/CustomValidations';
import { ENVIRONMENTS } from '@/helpers/constants';
import { formControlMixin } from '@/mixins/FormControlMixin';

const SWAL_CONFIG = {
  icon: 'info',
  showCancelButton: false,
  customClass: { confirmButton: 'btn btn-success btn-fill mr-2' },
  buttonsStyling: false
};

export default {
  name: 'GetStarted',
  components: { VueRecaptcha },
  mixins: [formControlMixin],
  $_veeValidate: { validator: 'new' },
  data() {
    return {
      modelValidations: {
        name: { required: true, min: 4 },
        email: { required: true, email: true },
        terms: { required: true },
        captcha: { required: true },
        password: { required: true }
      },
      APP_VERSION: process.env.VUE_APP_VERSION,
      name: '',
      email: '',
      password: '',
      terms: false,
      smsReceived: false,
      submitted: false,
      isPlatformActive: false,
      captcha: false,
      captchaError: false,
      error: false,
      year: new Date().getFullYear(),
      isSubmitting: false,
      passwordError: ''
    };
  },
  computed: {
    ...mapGetters({ facilitator: 'getFacilitator', invite: 'getInvite' }),
    backofficeUrl() {
      return `${this.$config.BACKOFFICE_URL}`;
    }
  },
  beforeCreate() {
    this.$store.dispatch('SET_INITIATE_FROM', 'Create an account');
    this.$validator.extend(validPhoneFormat.name, validPhoneFormat.obj);
  },
  async mounted() {
    for (const key in localStorage) {
      if (key !== 'branding') {
        localStorage.removeItem(key);
      }
    }
    if (this.$route.query?.invite) {
      try {
        await this.$store.dispatch('GET_INVITE', {
          inviteId: this.$route.query?.invite,
          spProductSlug: this.$route.params.spProductSlug,
          facId: this.facilitator.facId
        });
      } catch (error) {
        if (error.response.data.message === 'Invite already submitted') {
          this.$router
            .push({ name: 'InviteAlreadySubmited', params: { _normalPush: true } })
            .catch(() => {});
        } else if (error.response.data.message === 'Invite expired') {
          this.$router
            .push({ name: 'InviteExpired', params: { _normalPush: true } })
            .catch(() => {});
        }
      }

      if (this.invite) {
        this.name = this.invite.name;
        this.email = this.invite.email;
      }
    }
    this.$store.commit('MARK_STEP_COMPLETED', 0); // Updated from setStepProgress
    this.$store.commit('SET_FLOW_STATUS', {
      flowCompleted: false,
      flowStatus: null
    });
    this.$store.state.spProductName = this.$route.params.spProductSlug;
    if (
      this.$ENV !== ENVIRONMENTS.PROD &&
      this.$route.params.spProductSlug.slice(-5) === '-demo'
    ) {
      if (!this.invite) {
        const randomInt = Math.floor(2234000000 + Math.random() * 900000).toString();
        this.name = `${this.$store.state.spProductName}.${randomInt}`;
        this.email = `${this.name}@testdomain.com`;
      }
      this.password = 'Demotest2019!';
      this.terms = true;
      this.captcha = true;
      if (this.$route.params.spProductSlug.slice(-5) === '-demo') {
        for (const key in this.$refs) {
          if (key.indexOf('input-') > -1) {
            this.$refs[key]._data.touched = true;
          }
        }
      }
    }
    if (this.$ENV !== ENVIRONMENTS.PROD) this.captcha = true;
    localStorage.setItem('identityCheck', '');
    localStorage.removeItem('accountInfo');
    this.setCaptchaTabIndex();
  },
  methods: {
    toggleCheckboxAndFocusNext(nextRef) {
      this.terms = !this.terms;
      if (this.terms) {
        this.focusNext('terms', nextRef);
      }
    },
    setCaptchaTabIndex() {
      this.$nextTick(() => {
        const iframe = document.querySelector('#captcha-wrapper iframe');
        if (iframe) {
          iframe.setAttribute('tabindex', '-1');
        }
      });
    },
    async submitForm() {
      if (this.submitted || this.isSubmitting) return;

      const isValid = await this.$validator.validateAll();
      if (!isValid) {
        this.focusFirstError();
        return;
      }

      this.isSubmitting = true;
      this.passwordError = ''; // Reset password error
      try {
        if (!this.captcha) {
          this.captchaError = true;
        }
        const isPasswordValid = await this.$refs['input-password'].isPasswordValid();

        if (isValid && isPasswordValid && !this.captchaError) {
          await this.createAccount();
        } else {
          const errorMessage = isPasswordValid
            ? 'There are form errors, please check.'
            : 'Password does not meet the requirements.';
          this.$toasted.show(errorMessage, {
            type: 'error',
            duration: 3000,
            position: 'top-right'
          });
          if (!isPasswordValid) {
            this.passwordError = 'Password does not meet the requirements.';
          }
        }
      } catch (error) {
        console.error('Form submission failed:', error);
        this.handleAxiosError(error);
      } finally {
        this.isSubmitting = false;
        this.submitted = false;
      }
    },
    async createAccount() {
      this.submitted = true;
      const loader = this.$loading.show({ container: this.$refs.getStarted });
      try {
        await this.initiate();
      } catch (error) {
        this.handleAxiosError(error);
      } finally {
        loader?.hide();
      }
    },
    async initiate() {
      try {
        const browserInfo = await this.$store.dispatch('GET_BROWSER_INFO');
        const initiateParams = {
          facId: this.facilitator.facId,
          spProductSlug: this.$route.params.spProductSlug,
          name: this.name,
          password: this.password,
          email: this.email,
          browserInfo,
          inviteId: this.invite?.inviteId
        };
        // 'of' means onboarding flow
        if (this.$route.query?.of) initiateParams.ofQuery = this.$route.query.of;
        const response = await this.$axios({
          url: '/onboarding/accounts/initiate',
          method: 'POST',
          data: initiateParams
        });
        const { data } = response;
        if (data.success) {
          this.$axios.defaults.headers.common['Authorization'] =
            `Bearer ${response.data.token}`;
          this.$store.commit('MARK_STEP_COMPLETED', 1); // Updated from setStepProgress

          // Reset the significant changes flag before navigation
          this.$store.dispatch('RESET_SIGNIFICANT_CHANGES');

          await this.$router.push({
            name: 'MobileCodeVerification',
            params: {
              spProductSlug: this.$route.params.spProductSlug,
              _normalPush: true
            },
            query: this.$route.query
          });
        }
      } catch (error) {
        this.handleAxiosError(error);
        this.submitted = false;
      }
    },
    async handleAxiosError(error) {
      const errorMessage =
        (error &&
          error.response &&
          error.response.data &&
          (error.response.data.error || error.response.data.message)) ||
        '';

      if (errorMessage.toLowerCase().includes('password')) {
        this.passwordError = errorMessage;
      } else if (errorMessage.toLowerCase().includes('backoffice')) {
        const result = await swal.fire({
          title: `Your application has already been submitted.`,
          text: `You may proceed to log in to the backoffice.`,
          confirmButtonText: 'Go to backoffice',
          ...SWAL_CONFIG
        });
        if (result.value) window.location.replace(this.backofficeUrl);
      } else if (errorMessage.toLowerCase().includes('onboarding')) {
        const result = await swal.fire({
          title: `There is already an application in progress`,
          text: `You can continue with that one.`,
          confirmButtonText: 'Continue onboarding',
          ...SWAL_CONFIG
        });
        if (result.value) {
          await this.$router
            .push({
              name: 'continue',
              params: {
                spProductSlug: this.$route.params.spProductSlug,
                email: this.email,
                password: this.password,
                _normalPush: true
              },
              query: this.$route.query
            })
            .catch(() => {});
        }
      } else {
        const { data } = error && error.response;
        this.error = false;
        if (data && errorMessage) {
          this.$toasted.show(errorMessage, {
            type: 'error',
            duration: 3000,
            position: 'top-right'
          });
        } else if (error.response && error.response.status === 403) {
          this.error = true;
        } else {
          const logObject = {
            module: 'onboarding',
            details: {
              page: 'GetStarted.initiate',
              endpoint: '/onboarding/accounts',
              data
            }
          };
          this.$store.commit('logError', logObject);
          this.$toasted.show('Server side error, please contact the support team.', {
            type: 'error',
            duration: 3000,
            position: 'top-right'
          });
        }
      }
    },
    onVerify() {
      this.captcha = true;
      this.captchaError = false;
    },
    accept() {
      this.terms = !this.terms;
    },
    getError(fieldName) {
      if (fieldName === 'password' && this.passwordError) {
        return this.passwordError;
      }
      return this.errors.first(fieldName);
    },
    async continueApplication() {
      if (this.submitted || this.isSubmitting) return;

      this.isSubmitting = true;
      try {
        await this.$router
          .push({
            name: 'continue',
            params: {
              spProductSlug: this.$route.params.spProductSlug,
              _normalPush: true
            },
            query: this.$route.query
          })
          .catch(() => {});
        // Mark the first step as completed
        this.$store.commit('MARK_STEP_COMPLETED', 0);
      } catch (error) {
        console.error('Navigation failed:', error);
        this.$toasted.error('Failed to continue. Please try again.');
      } finally {
        this.isSubmitting = false;
      }
    },
    focusFirstError() {
      const firstErrorField = this.errors.items[0];
      if (firstErrorField) {
        const fieldName = firstErrorField.field;
        if (fieldName === 'terms') {
          this.$refs.terms.focus();
        } else {
          const input = this.$refs[`input-${fieldName}`];
          if (input && input.setFocus) {
            input.setFocus();
          }
        }
      }
    }
  }
};
</script>

<style lang="scss">
#get-started {
  .app-version {
    position: relative;
    top: 10px;
    color: var(--facilitator-theme-color);
    font-size: 12px;
  }
  .company-tnc-checkbox {
    input[type='checkbox'] {
      border-color: var(--main-primary-color-lighter, unset);
      &::before {
        box-shadow: inset 35px 1em var(--main-primary-color-lighter, unset) !important;
      }
      &:focus {
        outline: 2px solid var(--main-primary-color-lighter, unset) !important;
        box-shadow: 0 0 5px rgba(var(--main-primary-color-lighter, unset), 0.5) !important;
      }
    }
  }
  .onboarding-input:focus,
  .prev-app-button:focus {
    border: 2px solid #0f965e;
  }

  .continue-button {
    margin-top: 11px;
  }

  #captcha-wrapper > div > div {
    margin: auto;
  }
}
</style>
